import '../cookies.js'

var toggleMenus = document.querySelectorAll('[data-trigger=toggle]')
toggleMenus.forEach(node => {
  node.addEventListener('click', e => {
    var menu = node.parentElement.querySelector('[data-node=toggleMenu]')

    if (menu.classList.contains('w--open')) {
      menu.classList.remove('w--open')
    } else {
      document.querySelectorAll('[data-node=toggleMenu].w--open').forEach(menuNode => menuNode.classList.remove('w--open'))
      menu.classList.add('w--open')
    }
  })
})

var menuLinks = document.querySelectorAll('[data-node=toggleMenu] a')
menuLinks.forEach(link => {
  link.addEventListener('click', e => {
    var target = document.querySelector(link.getAttribute('href'))
    window.scrollTo({
      top: target.offsetTop,
      left: 0,
      behavior: "smooth"
    })
    e.preventDefault()
  })
})
